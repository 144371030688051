<template>
  <div
    v-if="showCookieMessage"
    class="cookie"
  >
    <div class="cookie__text">
      Продолжая использовать наш сайт, вы даёте
      <BaseLink
        href="/about/cookie"
        target="_blank"
        >согласие на использование файлов cookie
      </BaseLink>
    </div>
    <BaseButton2
      class="base-button-small base-button-second cookie__button"
      @click="acceptCookies"
      >Понятно
    </BaseButton2>
  </div>
</template>

<script setup lang="ts">
const cookiesAccepted = useCookie("donut-cookies-accepted-main", { maxAge: 31536000 });
const showCookieMessage = ref(!cookiesAccepted.value);

const acceptCookies = () => {
  cookiesAccepted.value = "true";
  showCookieMessage.value = false;
};
</script>

<style scoped>
.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--grey-7);
  box-shadow: 0px 4px 16px 0px #0000001f;
  padding: 22px 16px;
  z-index: calc(var(--z-index-header) + 1);
  border-radius: 12px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: var(--grey-1);
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.cookie__text {
  min-width: 100%;
  margin-bottom: 12px;
}

@media (--desktop) {
  .cookie__text {
    min-width: 568px;
    margin-bottom: 0;
  }

  .cookie {
    bottom: 40px;
    left: 40px;
    padding: 22px 16px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: var(--grey-1);
    flex-direction: row;
    width: auto;
    align-items: center;
  }
}

.cookie__button {
  color: var(--grey-1);
}

.cookie .cookie__text a {
  color: var(--orange-1);
  display: inline;
}

.cookie .cookie__text a:visited {
  color: var(--orange-1);
}
</style>
